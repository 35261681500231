import ajax from "../utils/request";
// 用户登录
// export const ReqLogin = (params:IParams) => ajax(`${BASE_URL}login`, params, 'POST') // POST 是默认值，可以不写
// const BASE_URL:string = "https://merch-mixer-test.daijc.cn"
export const brandListApi = (params:any) => ajax(`/api/merch-mixer-core/brand/mobile/list`, params,'GET')
export const modelListApi = (params:any) => ajax(`/api/merch-mixer-core/model/mobile/list`, params,'GET')
export const marketPriceListApi = (params:any) => ajax(`/api/merch-mixer-core/market/price/mobile/list`, params,'GET')
// // 用户注册
// export const userRegister = (data) => {
//     return instance.request({
//         url: "",
//         method: "POST",
//         data
//     });
// };

