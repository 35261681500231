import React, {useEffect, useState} from 'react';
import { Card, List, Divider } from 'antd';

export const AnchorContent =  (props: any) => {
    const [anchorContents, setAnchorContents] = useState([])
    const [modelName, setModelName] = useState([])

    useEffect(()=>{
        setAnchorContents(props?.anchorData)
        setModelName(props?.modelName)
    },[props])
    return <Card title={modelName} style={{border: 0,height:'calc(100vh - 125px)', overflow: 'scroll'}}>
            {
                anchorContents && anchorContents?.length > 0 && anchorContents.map((item: any) => (
                    <>
                        <List
                            size={"small"}
                            key= {item.storageId}
                            header={<>
                                <div style={{color: '#333',fontSize: '13px'}}>{`${item.modelName} ${item.storageName}(${item.officialPrice})`}</div>
                                <span style={{fontWeight: 300, fontSize: "12px"}}>{item.marketTime}</span>
                            </>
                        }
                            dataSource={item?.colorMarketPriceList}
                            renderItem={(subItem: any) => (
                                <List.Item key = {subItem.colorId} style={{fontWeight: 300, fontSize: "12px"}}>
                                    <span>{subItem.colorName}</span>
                                    {subItem.marketPrice ? <>
                                        <div>
                                            <span style={{marginRight: "30px", background: "#f0f0f0", borderRadius: "2px",padding: "3px",width: "40px",display: "inline-block", textAlign: "center"}}>{subItem.marketPrice}</span>
                                            <span
                                                style={{
                                                    textAlign: 'center',
                                                    display: 'inline-block',
                                                    width: '40px',
                                                    color:
                                                        subItem &&
                                                        (subItem.priceComparison === 0
                                                            ? '#333'
                                                            :  subItem.priceComparison < 0
                                                                ? 'green'
                                                                : 'red')}}
                                            >{subItem && subItem.priceComparison}
                                            </span>
                                        </div>

                                    </> : <>
                                        <div>
                                            <span style={{marginRight: "30px", background: "#f0f0f0", borderRadius: "2px",padding: "3px",width: "40px",display: "inline-block", textAlign: "center", textDecoration: 'line-through'}}>{subItem.prevPrice}</span>
                                            <span
                                                style={{
                                                    display: 'inline-block',
                                                    width: '40px',
                                                    textAlign: 'center',
                                                    textDecoration: 'line-through',
                                                    color: subItem &&
                                                    (subItem.priceComparison === 0
                                                        ? '#333'
                                                        :  subItem.priceComparison < 0
                                                            ? 'green'
                                                            : 'red')}}
                                            >{subItem.priceComparison}</span>
                                        </div>
                                    </>
                                    }
                                </List.Item>
                            )}
                        />
                        <Divider  style={{marginTop: 0,marginBottom: "10px"}} />
                    </>
                ))
            }
        </Card>
}