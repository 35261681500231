import React, {useState, useEffect} from 'react';
import { Tabs } from 'antd';
import {AnchorTabs} from "./components/Anchor";
import {brandListApi, modelListApi} from '../../services/h5'
import { WaterMark } from '@ant-design/pro-components'

const H5: React.FC = () => {
    const [items, setItems] = useState()
    const fetchData =  async (key: any) => {
        const tabData: any = await brandListApi({})
        const anchorData: any = await modelListApi({brandId: key ? key : tabData[0].id })
        const transformData = tabData.map((item: any) => ({
            label: item.name,
            key: item.id,
            children: <AnchorTabs key={item.id} brandId={item.id} data={anchorData}/>
        }))
        setItems(transformData)
    }
    useEffect(()=> {
            fetchData(null);
    },[])
    return (<>
    <WaterMark content="云际科技" offsetLeft={50} gapY={150} gapX={150}>
        <div>
                <Tabs
                    style={{height: 'calc(100vh - 50px)'}}
                    defaultActiveKey="1"
                    centered
                    items={items}
                    onTabClick={(key)=>{
                        fetchData( key)
                    }}
                />
                <div style={{height: '50px',fontSize: '12px', lineHeight: '30px',textAlign: 'center',fontWeight: 300,color: '#1677ff'}}>划线为当天报价未更新</div>
            </div>
    </WaterMark>
    </>
    );
};

export default H5;