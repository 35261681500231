import React, {useEffect, useState} from 'react';
import { Tabs } from 'antd';
import {AnchorContent} from './AnchorContent'
import {marketPriceListApi} from "../../../services/h5";
import './anchor.css'

export const AnchorTabs = ({data, brandId}: any) => {
    const [anchorItems, setAnchorItems] = useState()

    const fetchMarketPriceData = async (brandId: any, modelId:any) => {
        var anchorData: any = []
        if(brandId && modelId){
            anchorData = await marketPriceListApi({brandId: brandId,modelId: modelId })
        }
        const transformData = data?.map((item: any) => ({
            label: <span style={{fontSize: '12px'}}>{item.name}</span>,
            key: item.id,
            children: <AnchorContent key={item.id} modelName={item.name} anchorData={anchorData} />
        }))
        setAnchorItems(transformData)
        anchorData = null
    }
    useEffect(()=>{
        // 调行情接口
        fetchMarketPriceData(brandId, data?.[0]?.id)
    },[data])
    return <>
        <div className="anchorBox">
            <Tabs
                indicatorSize={15}
                tabPosition="left"
                items={anchorItems}
                onTabClick={(key)=>{
                    fetchMarketPriceData(brandId,key)
                }}
            />
        </div>

    </>
}
